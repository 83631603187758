import axios from "axios";
const state = {
    customerDetail: null,
    emissionHistoryUUID: null,
    compensationHistoryUUID: null,
    villageHistoryUUID: null
};
const getters = {
    getOpenCustomerDetail(state) {
        return state.customerDetail;
    },
    getEmissionHistoryUUID(state) {
        return state.emissionHistoryUUID;
    },
    getCompensationHistoryUUID(state) {
        return state.compensationHistoryUUID;
    },
    getVillageHistoryUUID(state) {
        return state.villageHistoryUUID;
    },
};
const mutations = {
    setOpenCustomerDetail(state, payload) {
        state.customerDetail = payload;
    },
    setEmissionHistoryUUID(state, payload) {
        state.emissionHistoryUUID = payload;
    },
    setCompensationHistoryUUID(state, payload) {
        state.compensationHistoryUUID = payload;
    },
    setVillageHistoryUUID(state, payload) {
        state.villageHistoryUUID = payload;
    }
};
const actions = {
    async doOpenCustomerDetail(ctx, customer_uuid) {
        if (customer_uuid == null) {
            ctx.commit("setOpenCustomerDetail", null);
            return;
        }
        const sessionUUID = localStorage.getItem("XSessionUUID");
        const response = await axios.get("/api/admin/v2/customer/" + customer_uuid, {
            headers: {
                "X-Session-Token": sessionUUID
            }
        });
        if (typeof response != "boolean") {
            ctx.commit("setOpenCustomerDetail", response.data.response);
        }
        else {
            ctx.commit("setOpenCustomerDetail", null);
        }
        return;
    },
    async doSetEmissionHistoryUUID(ctx, customer_uuid) {
        ctx.commit("setEmissionHistoryUUID", customer_uuid);
    },
    async doSetCompensationHistoryUUID(ctx, customer) {
        ctx.commit("setCompensationHistoryUUID", customer);
    },
    async doSetVillageHistoryUUID(ctx, customer) {
        ctx.commit("setVillageHistoryUUID", customer);
    }
};
export default {
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions
};
