import axios from "axios";
export default class ApiUat {
    static async CreateCustomer(email, password) {
        try {
            const response = await axios.post("/api/debug/uat/customer/create", {
                email: email,
                password: password,
                has_itsme: false,
            });
            return response.data;
        }
        catch (e) {
            console.error("Error in CreateCustomer");
            return false;
        }
    }
    static async SearchCustomer(email) {
        try {
            const response = await axios.get("/api/debug/uat/customer/session?email=" + email);
            return response.data.response;
        }
        catch (e) {
            console.error("Error in SearchCustomer");
            return false;
        }
    }
    static async ConfirmEmail(email) {
        try {
            const response = await axios.post("/api/debug/uat/customer/email/confirm", {
                email: email,
            });
            return response.data.status;
        }
        catch (e) {
            console.error("Error in ConfirmEmail");
            return false;
        }
    }
    static async AcceptTerms(email) {
        try {
            const response = await axios.post("/api/debug/uat/customer/accept", {
                email: email,
            });
            return response.data.response;
        }
        catch (e) {
            console.error("Error in AcceptTerms");
            return false;
        }
    }
    static async DeattachProduct(customer_product_uuid) {
        try {
            const response = await axios.post("/api/debug/uat/customer_product/deattach", {
                customer_product_uuid: customer_product_uuid,
            });
            return response.data.response;
        }
        catch (e) {
            console.error("Error in DeattachProduct");
            return false;
        }
    }
    static async ActivationProduct(order_product_uuid, iccidOrNsce) {
        try {
            const response = await axios.post("/api/debug/uat/order_product/activation", {
                order_product_uuid: order_product_uuid,
                iccidOrNsce: iccidOrNsce,
            });
            return response.data.response;
        }
        catch (e) {
            console.error("Error in DeattachProduct");
            return false;
        }
    }
    static async UpdateOrderProductStatus(order_product_uuid, status) {
        try {
            const response = await axios.post("/api/debug/uat/order_product/status", {
                order_product_uuid: order_product_uuid,
                status: status,
            });
            return response.data.response;
        }
        catch (e) {
            console.error("Error in UpdateOrderProductStatus");
            return false;
        }
    }
    static async UpdateCustomerProductStatus(customer_product_uuid, status) {
        try {
            const response = await axios.post("/api/debug/uat/customer_product/status", {
                customer_product_uuid: customer_product_uuid,
                status: status,
            });
            return response.data.response;
        }
        catch (e) {
            console.error("Error in UpdateCustomerProductStatus");
            return false;
        }
    }
    static async SetPersonalInfo(email) {
        try {
            const response = await axios.post("/api/debug/uat/customer/change/personal", {
                email: email,
                personal_data: {
                    first_name: "Dmitrii",
                    last_name: "Paranosenkov",
                    birth_data: {
                        birth_at: "01-01-2000",
                        birth_city: "Moscow",
                        birth_country: "Russia",
                    },
                },
                personal_address: {
                    zip_code: "410003",
                    city: "Moscow",
                    street_name: "Lenana",
                    street_number: "1",
                    street_box: "",
                },
                shipping_address: {
                    zip_code: "410003",
                    city: "Moscow",
                    street_name: "Lenana",
                    street_number: "1",
                    street_box: "",
                },
            });
            return response.data.status;
        }
        catch (e) {
            console.error("Error in SetPersonalInfo");
            return false;
        }
    }
    static async SetBusinessInfo(email) {
        try {
            const response = await axios.post("/api/debug/uat/customer/change/business", {
                email: email,
                company_data: {
                    company_name: "string",
                    vat_number: "string",
                    name_of_legal: "string",
                    surname_of_legal: "string",
                },
                company_address: {
                    zip_code: "string",
                    city: "string",
                    street_name: "string",
                    street_number: "string",
                    street_box: "string",
                },
                shipping_address: {
                    zip_code: "string",
                    city: "string",
                    street_name: "string",
                    street_number: "string",
                    street_box: "string",
                },
            });
            return response.data.status;
        }
        catch (e) {
            console.error("Error in SetPersonalInfo");
            return false;
        }
    }
}
